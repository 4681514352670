import { createAsyncGetters } from '../../createAsyncGetters';

interface AIRequest {
  API_KEY: string;
}

export const aiAsyncGetters = createAsyncGetters({
  getUserByAPIKey: async ({ API_KEY }: AIRequest, api) => {
    const searchResponse = await api.searchPlayers({
      where: {
        apiKey: {
          isAnyOf: [API_KEY],
        },
      },
      limit: 10,
    });

    return searchResponse.results[0];
  },
});
