import {
  action,
  asyncAction,
  ReplicantAsyncActionAPI,
  ReplicantEventHandlerAPI,
} from '@play-co/replicant';
import { createActions } from '../../createActions';
import { TradingMemeInput } from './types';
import {
  confirmTransaction,
  getActualTime,
  getBuyEstimate,
  getCoinAmountForPointSell,
  getCreateEstimate,
  getFtueShareGateReward,
  getMyOffchainMemeIds,
  getMeme,
  getSellEstimate,
  getTokenPrice,
  getTTGCanClaim,
  getTTGFarmingPoints,
  getTxWithinEstimate,
  hasReachedMemeCreationLimit,
  hasReachedMemeHoldingLimit,
  getWallet,
  isMemeGraduationComplete,
} from './tradingMeme.getters';
import { ErrorCode, errorResponse, successResponse } from '../../response';
import {
  giveFreeToken,
  incrementBalance,
  incrementScore,
  spendCoins,
} from '../game/game.modifiers';
import {
  memeGiftRuleset,
  minTxVerificationDelayMs,
  pointEpsilon,
  shortestPortofolioUpdateInterval,
  tmgRuleset,
  txConfig,
} from './tradingMeme.ruleset';
import { updateStatus } from './tradingMeme.utils';
import {
  // savePortfolioPricePoint,
  tmgSyncTapsOnSessionComplete,
  startGameSession,
  convertScore,
  updatePointHolding,
  tmgStartTokenFarming,
  updateWalletHoldings,
  initiateDailyTokenClaim,
  runTxWatcher,
  runTxConfirmation,
  initiateGradPointClaim,
  grantClaimableTokens,
} from './tradingMeme.modifiers';
import { PeriodicUpdate } from './tradingMeme.messages';
import { MutableState } from '../../schema';
import { fetchPlayerState } from '../game/game.getters';
import { retry } from '../../lib/async';
import { stage } from '../game/game.config';
import {
  OnchainUserProfile,
  TradingMemeStatus,
  TxType,
} from './tradingMeme.schema';
import { DAY_IN_MS, MIN_IN_MS, SEC_IN_MS } from '../../utils/time';
import {
  getOwnMemeGiftId,
  getUserMemeGiftId,
  hasReceivedUserMemeGift,
} from '../powerups/getters';
import { HP } from '../../lib/HighPrecision';
import { getOnchainHoldersStateId } from '../onchainHolders/onchainHolders.getters';
import { generateNonce } from '../../utils/numbers';
import { getJettonContractAddressFromMetadata } from './tradingMeme.getters.ton';

const CREATE_MEME_MAX_RETRIES = 20;

export async function createTradingMemeId(
  state: MutableState,
  api: ReplicantAsyncActionAPI<any> | ReplicantEventHandlerAPI<any>,
) {
  if (hasReachedMemeCreationLimit(state)) {
    return errorResponse('Creation limit reached', {
      code: ErrorCode.CREATION_LIMIT_REACHED,
    });
  }

  async function createWithLinearId(retries = 0) {
    try {
      // @note: the meme indexing can take time to update or even fail
      // and if that happens then this logic will fail as well as # of memes indexed will be smaller than the last meme id
      // const memeIndex = await api.sharedStates.tradingMeme.count({
      //   where: { id: { isNotOneOf: ['NOT_A_REAL_ID'] } },
      // });
      // replacing that logic temporarily with randomly selecting
      const memeIndex = Math.floor(Math.random() * Number.MAX_SAFE_INTEGER);
      console.log({ memeIndex });
      // Create shared state for offchainToken.
      const memeId = api.sharedStates.tradingMeme.create(memeIndex.toString());
      api.sharedStates.tradingMeme.create(memeIndex.toString());

      const holdersStateId = getOnchainHoldersStateId(memeId);
      api.sharedStates.onchainHolders.create(holdersStateId);
      api.sharedStates.onchainHolders.postMessage.createHolders(
        holdersStateId,
        { memeId },
      );

      return memeId;
    } catch (e) {
      console.log(e);
      if (retries >= CREATE_MEME_MAX_RETRIES) {
        throw new Error('Create meme timeout, please try again later.');
      }
      return createWithLinearId(++retries);
    }
  }

  try {
    const memeId = await createWithLinearId();

    return successResponse({ memeId });
  } catch (e: any) {
    return errorResponse(e.message, {
      code: ErrorCode.MEME_CREATION_TIMEOUT,
    });
  }
}

export interface CreateCardPayload {
  memeId: string;
  memeInput: TradingMemeInput;
  memeAddressSeed: {
    id: string;
    name: string;
    description: string;
    ticker: string;
    image: string;
  };
  isLocal: boolean;
}

async function createTradingMeme(
  state: MutableState,
  payload: CreateCardPayload,
  api: ReplicantAsyncActionAPI<any>,
) {
  try {
    // make sure the token with the id exists
    // @ts-ignore (TODO: fix type)
    const exists = await api.asyncGetters.getMeme({
      memeId: payload.memeId,
    });
    console.log('createTradingMeme', { exists, payload });
    if (!exists) {
      throw new Error(`Trying to create meme but cannot find 'memeId'.`);
    }

    const timestamp = getActualTime(api);

    const jettonContractAddress = await getJettonContractAddressFromMetadata(
      payload.memeAddressSeed,
    );
    if (!jettonContractAddress) {
      throw new Error(`Could not get contract address! Something went wrong`);
    }

    const memeDetails = {
      creatorId: state.id,
      creatorName: state.profile.name,
      creatorImage: state.profile.photo,
      // @todo: update availableAt property only after moderation
      availableAt: timestamp,
      ...payload.memeInput,
    };

    const props = {
      details: memeDetails,
      timestamp,
      isDev: payload.isLocal,
      jettonContractAddress,
    };

    console.log('createTradingMeme', { props });

    // Populate the new offchainToken data
    api.sharedStates.tradingMeme.postMessage.createMemeMessage(
      payload.memeId,
      props,
    );

    state.trading.lastTokenCreatedTimestamp = timestamp;
    state.trading.createdMemes = true;

    return successResponse({});
  } catch (e: any) {
    return errorResponse(e.message);
  }
}

export const tradingMemeActions = createActions({
  // asyncTakePortfolioSnapshots: asyncAction(async (state, _: void, api) => {
  //   if (getMyOffchainMemeIds(state).length === 0) {
  //     return;
  //   }

  //   // generate a portfolio price point
  //   await savePortfolioPricePoint(state, api);

  //   // schedule another one for later
  //   // overrides previous schedules
  //   api.scheduledActions.schedule.savePortfolioPricePoint({
  //     args: { delay: shortestPortofolioUpdateInterval },
  //     notificationId: 'savePortfolioPricePoint',
  //     delayInMS: shortestPortofolioUpdateInterval,
  //   });
  // }),
  asyncGetNewMemeId: asyncAction(async (state, _, api) => {
    return createTradingMemeId(state, api);
  }),
  asyncCreateMeme: asyncAction(
    async (state, payload: CreateCardPayload, api) => {
      return createTradingMeme(state, payload, api);
    },
  ),
  asyncCreateOffchainTokenForTestUser: asyncAction(
    async (
      state,
      payload: { testUserId: string; createCardPayload: CreateCardPayload },
      api,
    ) => {
      const testUserState = await api.asyncGetters.getUserState({
        userId: `${state.id}_${payload.testUserId}`,
      });

      incrementScore(testUserState, 10000);

      return await createTradingMeme(
        testUserState,
        payload.createCardPayload,
        api,
      );
    },
  ),
  asyncEditOffchainToken: asyncAction(
    async (
      state,
      {
        tokenId,
        telegramChannelLink,
        telegramChatLink,
        twitterLink,
        websiteLink,
      }: {
        tokenId: string;
        telegramChannelLink?: string;
        telegramChatLink?: string;
        twitterLink?: string;
        websiteLink?: string;
      },
      api,
    ) => {
      try {
        await api.sharedStates.tradingMeme.postMessage.editOffchainToken(
          tokenId,
          {
            telegramChannelLink,
            telegramChatLink,
            twitterLink,
            websiteLink,
          },
        );

        return successResponse({});
      } catch (e: any) {
        return errorResponse(e.message);
      }
    },
  ),
  buyOffchainToken: asyncAction(
    async (
      state,
      payload: {
        memeId: string;
        pointAmountEstimate: string;
        currencyAmount: string;
        testUserId?: string;
      },
      api,
    ) => {
      let userState = state;
      if (payload.testUserId) {
        const testUserState = await api.asyncGetters.getUserState({
          userId: `${userState.id}_${payload.testUserId}`,
        });
        if (testUserState) {
          userState = testUserState;
        }
      }

      const { memeId, pointAmountEstimate, currencyAmount } = payload;

      if (hasReachedMemeHoldingLimit(state, memeId)) {
        return errorResponse('Holding limit reached', {
          code: ErrorCode.HOLDING_LIMIT_REACHED,
        });
      }

      const pointAmountEstimateBig = HP(pointAmountEstimate);
      const currencyAmountBig = HP(currencyAmount);
      const meme = await api.sharedStates.tradingMeme.fetch(memeId);

      const memeState = meme?.global;
      if (!memeState) {
        throw new Error(
          `Attempting to buy meme that doesn't exist '${memeId}'.`,
        );
      }

      const cannotAfford = currencyAmountBig.gt(userState.balance);

      if (cannotAfford) {
        return errorResponse('Not enough funds', {
          code: ErrorCode.NOT_ENOUGH_FUNDS,
          // data: { diff: offchainTokenBuyPrice - userState.balance },
        });
      }

      const driftPct = userState.trading.maxSlippage;
      const pointAmount = getBuyEstimate(memeState, currencyAmountBig);

      const priceWithinRange = getTxWithinEstimate(
        pointAmount,
        pointAmountEstimateBig,
        driftPct,
      );

      if (!priceWithinRange) {
        return errorResponse('Price has changed', {
          code: ErrorCode.OFFCHAIN_TOKEN_PRICE_DRIFT,
          data: { newEstimate: pointAmount },
        });
      }

      const timestamp = getActualTime(api);
      const isGraduated = isMemeGraduationComplete(memeState);
      const pointHoldings = userState.trading.offchainTokens[memeId];

      api.sharedStates.tradingMeme.postMessage.attemptBuyOffchainToken(memeId, {
        timestamp,
        isGraduated,
        expectedTxIdx: memeState.offchainTxs.length,
        buyerId: userState.id,
        buyerName: userState.profile.name,
        buyerImage: userState.profile.photo,
        currencyAmount: currencyAmount.toString(),
        pointAmountEstimate: pointAmountEstimate.toString(),
        driftPct,
      });

      const txConfirmation = await confirmTransaction(
        api,
        userState.id,
        memeId,
        timestamp,
      );

      if (!txConfirmation) {
        return errorResponse('Failed to buy. Please try again', {
          code: ErrorCode.OFFCHAIN_TOKEN_PURCHASE_FAILED,
        });
      }

      const transaction = txConfirmation.transaction;

      // remove currencyAmount from user
      spendCoins(userState, currencyAmountBig.toNumber(), api.date.now());

      const currencyInvested = currencyAmountBig
        // .div(txConfig.buyModifier)
        .mul(1 - txConfig.fee)
        .round();

      const supply = memeState.pointSupply;
      const txPointAmount = transaction.pointAmount;

      // update offchainToken on user state
      updatePointHolding(
        state,
        memeId,
        {
          timestamp,
          dailyPoints: isGraduated ? txPointAmount : undefined,
          pointAmount: txPointAmount,
          pointsAccumulated: txPointAmount,
          currencyInvested: currencyInvested.toString(),
          lastNotifPrice: getTokenPrice(memeState),
        },
        true,
      );

      return successResponse(getMeme(txConfirmation.offchainToken, memeId));
    },
  ),
  sellOffchainToken: asyncAction(
    async (
      state,
      payload: {
        memeId: string;
        currencyAmountEstimate: string;
        pointAmount: string;
        testUserId?: string;
      },
      api,
    ) => {
      let userState = state;
      if (payload.testUserId) {
        const testUserState = await api.asyncGetters.getUserState({
          userId: `${state.id}_${payload.testUserId}`,
        });
        if (testUserState) {
          userState = testUserState;
        }
      }

      const { memeId, currencyAmountEstimate, pointAmount } = payload;
      const pointAmountBig = HP(pointAmount);
      const currencyAmountEstimateBig = HP(currencyAmountEstimate);
      const offchainTokenState = await api.sharedStates.tradingMeme.fetch(
        memeId,
      );
      const offchainToken = offchainTokenState?.global;
      if (!offchainToken) {
        throw new Error(
          `Attempting to buy offchainToken that doesn't exist '${memeId}'.`,
        );
      }

      const ownsEnoughOffchainTokens = HP(
        state.trading.offchainTokens[memeId]?.pointAmount,
      ).gte(pointAmount);

      if (!ownsEnoughOffchainTokens) {
        return errorResponse('Do not have enough offchainTokens to sell');
      }

      const currentSupply = HP(offchainToken.pointSupply);
      const supplyAfterSell = currentSupply.minus(pointAmount);

      if (supplyAfterSell.lt(0)) {
        return errorResponse('Cannot sell last copy of a offchainToken', {
          code: ErrorCode.CUSTOM_SENTRY_TRACK,
          data: {
            message: 'FE should not have allowed supplyAfterSell to go below 0',
            memeId,
            userId: state.id,
            supplyAfterSell,
          },
        });
      }

      // const offchainTokenSellPrice = getOffchainTokensPrice(amount, 'sell', currentOffchainTokenSupply - 1);
      // // Should never happen
      // if (!offchainTokenSellPrice || offchainTokenSellPrice === -1) {
      //   return errorResponse('Unexpected error occured.', {
      //     code: ErrorCode.CUSTOM_SENTRY_TRACK,
      //     data: {
      //       message: 'Could not find sell price for given sell token amount',
      //       offchainTokenId,
      //       userId: state.id,
      //       supplyAfterSell,
      //     },
      //   });
      // }
      const driftPct = state.trading.maxSlippage;
      const currencyAmount = getSellEstimate(offchainToken, pointAmountBig);

      const priceWithinRange = getTxWithinEstimate(
        currencyAmount,
        currencyAmountEstimateBig,
        driftPct,
      );

      if (!priceWithinRange) {
        return errorResponse('Price has changed', {
          code: ErrorCode.OFFCHAIN_TOKEN_PRICE_DRIFT,
          data: { newEstimate: currencyAmount },
        });
      }

      const isGraduated = offchainToken.isGraduated;
      const pointHoldings = state.trading.offchainTokens[memeId];
      const pointAmountBeforeCheck = pointHoldings.pointAmount;

      // @IMPORTANT: the timestampt has to be computed BEFORE call to grantClaimableTokens
      // the reason is that the timestamp used attemptSellOffchainToken should precede the generation of the claimable tokens
      // this way the sold points will necessary be attributed to the same day they were obtained
      const timestamp = getActualTime(api);

      // this has to be checked before performing a sell to verify the amount of token that can be sold
      if (isGraduated) {
        await grantClaimableTokens(state, api);
      }

      const currentPointAmount = pointHoldings.pointAmount;
      if (currentPointAmount !== pointAmountBeforeCheck) {
        return errorResponse('Your points were converted to claimable tokens', {
          code: ErrorCode.POINTS_CONVERTED_TO_TOKENS,
          data: { newEstimate: currencyAmount },
        });
      }

      api.sharedStates.tradingMeme.postMessage.attemptSellOffchainToken(
        memeId,
        {
          timestamp,
          isGraduated,
          expectedTxIdx: offchainToken.offchainTxs.length,
          sellerId: state.id,
          sellerName: state.profile.name,
          sellerImage: state.profile.photo,
          currencyAmountEstimate: currencyAmountEstimate.toString(),
          pointAmount: pointAmount.toString(),
          driftPct,
        },
      );

      const txConfirmation = await confirmTransaction(
        api,
        state.id,
        memeId,
        timestamp,
      );
      if (!txConfirmation) {
        return errorResponse('Failed to sell. Please try again', {
          code: ErrorCode.OFFCHAIN_TOKEN_PURCHASE_FAILED,
        });
      }

      const transaction = txConfirmation.transaction;
      const txPointAmount = transaction.pointAmount;

      const userReward = getCoinAmountForPointSell(
        currentSupply,
        HP(txPointAmount),
      );

      // const userReward = getFinalSellPrice(lastTx.currencyAmount);
      // give currencyAmount to user

      const currencyRecovered = Math.ceil(userReward.toNumber());
      incrementBalance(state, currencyRecovered);
      state.trading.offchain.currencyRecovered = HP(
        state.trading.offchain.currencyRecovered,
      )
        .plus(currencyRecovered)
        .round() //just in case
        .toString();

      // remove offchainTokens from user state

      const newPointAmount = HP(currentPointAmount).minus(pointAmount).round();
      pointHoldings.pointAmount = newPointAmount.toString();

      const dailyPoints = pointHoldings.dailyPoints;
      if (dailyPoints) {
        // remove sold points from daily points
        let pointsToDeduct = HP(pointAmount);
        for (let dayTime in dailyPoints) {
          const pointsOfTheDay = HP(dailyPoints[dayTime]);
          if (pointsToDeduct.gte(pointsOfTheDay)) {
            delete dailyPoints[dayTime];
            pointsToDeduct = pointsToDeduct.minus(pointsOfTheDay);
          } else {
            dailyPoints[dayTime] = pointsOfTheDay
              .minus(pointsToDeduct)
              .round()
              .toString();
            pointsToDeduct = HP(0);
            break;
          }
        }

        if (pointsToDeduct.gte(pointEpsilon)) {
          // should not happen
          console.error(
            `Could not deduct all the sold points from daily points. meme id: ${memeId}, remaining points: ${pointsToDeduct.toString()}`,
          );
        }
      }

      // if we have no more points remove from state
      // but do not delete if graduation tokens claimed, need to keep a trace
      if (newPointAmount.lte(0) && !pointHoldings.gradPointsClaimed) {
        delete state.trading.offchainTokens[memeId];
      } else {
        // --- added for roi
        const ratioTokensRemaining = newPointAmount.div(currentPointAmount);
        const newCurencyInvested = HP(pointHoldings.currencyInvested)
          .mul(ratioTokensRemaining)
          .round();
        pointHoldings.currencyInvested = newCurencyInvested.toString();

        pointHoldings.lastNotifPrice = getTokenPrice(offchainToken);
      }

      return successResponse({
        token: getMeme(txConfirmation.offchainToken, memeId),
        amount_divested: userReward,
      });
    },
  ),
  asyncAddImage: asyncAction(
    async (
      _state,
      { offchainTokenId, image }: { offchainTokenId: string; image: string },
      api,
    ) => {
      let offchainTokenState = await api.sharedStates.tradingMeme.fetch(
        offchainTokenId,
      );
      if (!offchainTokenState) {
        return errorResponse('OffchainToken not found');
      }
      api.sharedStates.tradingMeme.postMessage.addImageUrl(offchainTokenId, {
        image,
      });
      return successResponse({
        offchainTokenId,
        image,
      });
    },
  ),
  asyncUpdateStatus: asyncAction(
    async (
      _state,
      {
        offchainTokenIds,
        tokenStatus,
      }: { offchainTokenIds: string[]; tokenStatus?: string },
      api,
    ): Promise<
      {
        offchainTokenId: string;
        status: string;
      }[]
    > => {
      return await updateStatus(api, offchainTokenIds, tokenStatus);
    },
  ),
  removeDeletedOffchainTokens: asyncAction(
    async (
      state,
      { offchainTokens }: { offchainTokens: { id: string }[] },
      _api,
    ) => {
      for (const { id } of offchainTokens) {
        const offchainToken = state.trading.offchainTokens[id];
        if (offchainToken) {
          incrementBalance(
            state,
            Math.ceil(HP(offchainToken.currencyInvested).toNumber()),
          );
          delete state.trading.offchainTokens[id];
        }
      }
    },
  ),
  flushMessages: asyncAction(async (_state, _payload, api) => {
    await api.flushMessages();
  }),
  periodicUpdate: action(
    (state, payload: Record<string, PeriodicUpdate>, api) => {
      const tokenGameStates = state.trading.miniGames.state;
      Object.keys(tokenGameStates).forEach((tokenId) => {
        const stateUpdate = payload[tokenId];

        if (!stateUpdate) {
          return;
        }

        api.sharedStates.tradingMeme.postMessage.periodicUpdate(
          tokenId,
          stateUpdate,
        );
      });
    },
  ),

  /**
   * returns `true` if the token has started farming or `false` if it didnt
   */
  tmgStartFarmingToken: action((state, payload: { tokenId: string }, api) => {
    return tmgStartTokenFarming(state, payload, api.date.now());
  }),
  tmgClaimFarmingReward: asyncAction(
    async (state, { tokenId }: { tokenId: string }, api) => {
      const now = api.date.now();

      if (!getTTGCanClaim(state, tokenId, now)) {
        return '-1';
      }

      // give reward
      const reward = getTTGFarmingPoints(state, tokenId, now);

      const pointUpdate = await convertScore(state, api, {
        tokenId,
        score: reward,
      });

      if (!pointUpdate) {
        return '0';
      }

      updatePointHolding(state, tokenId, pointUpdate, true);

      state.trading.miniGames.state[tokenId].miningStart = undefined;

      const points = JSON.parse(
        JSON.stringify(pointUpdate.pointAmount),
      ) as string;

      return points;
    },
  ),
  tmgTokenTap: action((state, _, api) => {
    state.trading.miniGames.tapping.sessionTaps +=
      tmgRuleset.tappingScorePerTap;
  }),
  tmgStartSession: action((state, { tokenId }: { tokenId: string }, api) => {
    const sessionStarted = startGameSession(state, api, tokenId);
    const hasConsumedTicket = JSON.parse(JSON.stringify(sessionStarted));
    return hasConsumedTicket;
  }),
  tmgHandleTapSessionEnd: asyncAction(
    async (state, { tokenId }: { tokenId: string }, api) => {
      const taps = tmgSyncTapsOnSessionComplete(state, tokenId, api.date.now());

      if (stage !== 'prod') {
        api.sendAnalyticsEvents([
          {
            eventType: 'DebugTapSessionEnd1',
            eventProperties: {
              taps,
            },
          },
        ]);
      }

      try {
        const pointUpdate = await retry(
          () =>
            convertScore(state, api, {
              tokenId,
              score: taps,
            }),
          {
            attempts: 5,
          },
        );

        if (stage !== 'prod') {
          api.sendAnalyticsEvents([
            {
              eventType: 'DebugTapSessionEnd2',
              eventProperties: {
                taps,
                ...pointUpdate,
              },
            },
          ]);
        }

        updatePointHolding(state, tokenId, pointUpdate);

        state.trading.miniGames.tapping.sessionKickbackTaps += taps;
        state.trading.miniGames.tapping.sessionTokenId = tokenId;

        const points = JSON.parse(
          JSON.stringify(pointUpdate.pointAmount),
        ) as string;

        return points;
      } catch (error) {
        // @todo: shouldn't we return the ticket in that case?
        return '0';
      }
    },
  ),
  tmgTriggerKickbackReward: asyncAction(async (state, _, api) => {
    const tokenId = state.trading.miniGames.tapping.sessionTokenId;
    if (!tokenId) {
      return;
    }

    const taps = state.trading.miniGames.tapping.sessionKickbackTaps;

    state.trading.miniGames.tapping.sessionKickbackTaps = 0;
    delete state.trading.miniGames.tapping.sessionTokenId;

    const myReferrerId = state.referrer_id;
    const myReferrerTokenId = state.trading.referrerTokenId;
    if (!myReferrerId || myReferrerTokenId !== tokenId) {
      return;
    }

    // 10% bonus to direct referrer
    const R1Kickback = Math.round(taps * 0.1);
    if (R1Kickback <= 0) {
      return;
    }

    const myReferrerState = await fetchPlayerState(api, myReferrerId);
    if (!myReferrerState) {
      return;
    }

    let scoreConversionR1;
    try {
      scoreConversionR1 = await retry(
        () =>
          convertScore(myReferrerState, api, {
            tokenId,
            score: R1Kickback,
          }),
        {
          attempts: 5,
        },
      );
    } catch (error) {
      console.error(
        `Failed to convert kickback score from ${state.id} to direct referrer ${myReferrerId} for meme ${tokenId}`,
      );
    }

    // @note: message posting should be done outside of a try catch
    if (scoreConversionR1) {
      api.postMessage.creditReferrerKickBack(myReferrerId, {
        pointUpdate: scoreConversionR1,
        points: Number(scoreConversionR1.pointAmount),
        tokenId,
      });
    }

    // 2.5% bonus to referrer of our referrer
    const R2Kickback = Math.round(taps * 0.025);

    const parentReferrerId = myReferrerState.referrer_id;
    const parentReferrerTokenId = myReferrerState.trading.referrerTokenId;
    if (
      R1Kickback <= 0 ||
      !parentReferrerId ||
      parentReferrerTokenId !== tokenId
    ) {
      return;
    }

    const parentReferrerState = await fetchPlayerState(api, parentReferrerId);
    if (!parentReferrerState) {
      return;
    }

    let scoreConversionR2;
    try {
      scoreConversionR2 = await retry(
        () =>
          convertScore(parentReferrerState, api, {
            tokenId,
            score: R2Kickback,
          }),
        {
          attempts: 5,
        },
      );
    } catch (error) {
      console.error(
        `Failed to convert kickback score from ${state.id} to parent referrer ${parentReferrerId} for meme ${tokenId}`,
      );
    }

    // @note: message posting should be done outside of a try catch
    if (scoreConversionR2) {
      api.postMessage.creditReferrerKickBack(parentReferrerId, {
        pointUpdate: scoreConversionR2,
        points: Number(scoreConversionR2.pointAmount),
        tokenId,
      });
    }
  }),
  generateUserMemeGift: action((state, { memeId }: { memeId: string }, api) => {
    const shareTime = api.date.now();
    const giftId = getOwnMemeGiftId(memeId, shareTime);
    const reward = memeGiftRuleset.giftReward;

    // @todo: add restriction to send gifts?
    state.trading.userMemeGiftsSent[giftId] = {
      reward,
      shareTime,
      claimed: false,
    };

    return shareTime;
  }),
  grantFtueShareReward: asyncAction(
    async (state, { memeId }: { memeId: string }, api) => {
      if (state.trading.ftueShareGatePassed) {
        return;
      }
      state.trading.ftueShareGatePassed = true;

      const reward = getFtueShareGateReward(state);
      await giveFreeToken(state, api, memeId, reward);
    },
  ),
  claimUserMemeGift: asyncAction(
    async (
      state,
      {
        senderId,
        tokenId,
        shareTime,
      }: { tokenId: string; senderId: string; shareTime: number },
      api,
    ) => {
      const now = api.date.now();
      if (shareTime + DAY_IN_MS < now) {
        // gift is expired
        return {
          expired: true,
        };
      }

      // gift is not expired
      const giftId = getUserMemeGiftId(senderId, tokenId, shareTime);
      if (hasReceivedUserMemeGift(state, senderId, tokenId, shareTime)) {
        return {
          alreadyClaimed: true,
        };
      }

      // gift was not received by this player

      const senderState = await fetchPlayerState(api, senderId);
      if (!senderState) {
        // sender does not exist, hack attempt?
        return {
          cannotFindUserState: true,
        };
      }

      console.log('claimUserMemeGift', {
        senderState,
        id: getOwnMemeGiftId(tokenId, shareTime),
      });

      const gift =
        senderState.trading.userMemeGiftsSent[
          getOwnMemeGiftId(tokenId, shareTime)
        ];
      if (!gift) {
        // gift does not exists, hack attempt?
        return {
          noGift: true,
        };
      }

      // user is claiming a reward (either gift or consolation)
      state.trading.userMemeGiftsClaimed[giftId] = gift.shareTime;

      if (gift.claimed) {
        incrementBalance(state, memeGiftRuleset.consolationCoinReward);
        // already claimed
        return {
          consolation: memeGiftRuleset.consolationCoinReward,
        };
      }

      const scoreEquivalent = gift.reward;

      const receiverGiftGrant = await giveFreeToken(
        state,
        api,
        tokenId,
        scoreEquivalent,
      );

      // Update senders state to gift claimed
      api.postMessage.setGiftAsClaimed(senderId, {
        tokenId,
        shareTime,
      });

      const points = JSON.parse(
        JSON.stringify(receiverGiftGrant?.pointAmount),
      ) as string;

      return {
        points,
      };
    },
  ),
  onJettonContractMinted: action(
    (state, { memeId }: { memeId: string }, api) => {
      api.sharedStates.tradingMeme.postMessage.onJettonContractMinted(
        memeId,
        {},
      );
    },
  ),
  runTxConfirmationAsync: asyncAction(async (state, _, api) => {
    const updatedMemes: string[] = await runTxConfirmation(state, api);
    return updatedMemes;
  }),
  saveUnconfirmedTx: action(
    (
      state,
      payload: {
        walletAddress: string;
        memeId: string;
        txHash: string;
        txType: TxType;
      },
      api,
    ) => {
      console.log('saveUnconfirmedTx', { payload });
      const walletAddress = payload.walletAddress;
      const wallet = getWallet(state, walletAddress);

      console.log('saveUnconfirmedTx', { wallet });

      wallet.unconfirmedTxs.push({
        memeId: payload.memeId,
        txHash: payload.txHash,
        txType: payload.txType,
        createdAt: api.date.now(),
        verifDelayMs: minTxVerificationDelayMs,
      });
    },
  ),
  setGraduationClaimTime: action(
    (
      state,
      payload: {
        walletAddress: string;
        memeId: string;
        reset: boolean;
      },
      api,
    ) => {
      const wallet = state.trading.onchain.wallets[payload.walletAddress];
      if (!wallet) {
        return;
      }

      const memeHoldingsStatus = wallet.memeHoldings[payload.memeId];
      if (!memeHoldingsStatus) {
        return;
      }

      if (payload.reset) {
        delete memeHoldingsStatus.graduationClaimTime;
      } else {
        memeHoldingsStatus.graduationClaimTime = api.date.now();
      }
    },
  ),
  onDexContractMinted: action(
    (
      state,
      { memeId, contractAddress }: { memeId: string; contractAddress: string },
      api,
    ) => {
      api.sharedStates.tradingMeme.postMessage.onDexContractMinted(memeId, {
        contractAddress,
      });
    },
  ),
  onDexGraduationTriggered: action(
    (state, { memeId }: { memeId: string }, api) => {
      api.sharedStates.tradingMeme.postMessage.onDexGraduationTriggered(
        memeId,
        {},
      );
    },
  ),
  updateWalletHoldingsAsync: asyncAction(
    async (state, { walletAddress }: { walletAddress?: string }, api) => {
      if (!walletAddress) {
        return;
      }

      await updateWalletHoldings(state, walletAddress, api);
    },
  ),
  runTxWatcherAsync: asyncAction(
    async (
      state,
      payload: { memeId: string; creator: OnchainUserProfile },
      api,
    ) => {
      // this is a hack to test things locally
      await runTxWatcher(state, api, {
        memeId: payload.memeId,
        userProfile: payload.creator,
      });
    },
  ),
  // note: might need to reenable later
  // runTxWatcher: action(
  //   (state, payload: { memeId: string; creator: OnchainUserProfile }, api) => {
  //     api.scheduledActions.schedule.runTxWatcher({
  //       // target the creator user id so that only one watcher is triggered
  //       targetUserId: payload.creator.userId,
  //       // only one watcher per meme id
  //       notificationId: `runTxWatcher.${payload.memeId}`,
  //       args: {
  //         memeId: payload.memeId,
  //         userProfile: payload.creator,
  //       },
  //       // @note: not sure what the minimum possible delay is here
  //       delayInMS: 5 * SEC_IN_MS,
  //     });
  //   },
  // ),
  initiateDailyTokenClaim: action(
    (state, { memeId }: { memeId: string }, api) => {
      initiateDailyTokenClaim(state, memeId, api.date.now());
    },
  ),
  initiateGradPointClaim: action(
    (state, { memeId }: { memeId: string }, api) => {
      initiateGradPointClaim(state, memeId, api.date.now());
    },
  ),
});
